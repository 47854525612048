module.exports = {
  siteTitle: 'Techbridge', // <title>
  manifestName: 'Techbridge',
  manifestShortName: 'Techbridge', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'TECHBRIDGE3.0',
  subHeading: 'Bridge to Web3',

  // social
  socialLinks: [
    {
      icon: 'fa-discord',
      name: 'Discord',
      url: 'https://discord.gg/gEZVkMxWHC',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/techbridge_ca',
    },
  ],
  email: 'anubhav.srivastava00@gmail.com',
  phone: '000-00000',
  address: '305 Del Sol, Sunnyvale, CA',
};
